import React, { useState } from 'react';
import axios from 'axios';
import './AuthForm.css'; // Reuse your existing CSS for consistency

const ChangePassword = () => {
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    BOUsername: '',
    BOPassword: ''
  });

  const { oldPassword, newPassword, confirmPassword, BOUsername, BOPassword } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert('New password and confirm password do not match');
      return;
    }
    try {
      const response = await axios.post(`${axiosDomain}/api/auth/change-password`, { oldPassword, newPassword, BOUsername, BOPassword }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      alert('Password changed successfully');
      setFormData({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        BOUsername: '',
        BOPassword: ''
      });
    } catch (error) {
      alert('Failed to change password');
      console.error(error);
    }
  };

  return (
    <div className="auth-form">
      <form onSubmit={onSubmit}>
        <label>Old Password</label>
        <input
          type="password"
          name="oldPassword"
          value={oldPassword}
          onChange={onChange}
          required
        />

        <label>New Password</label>
        <input
          type="password"
          name="newPassword"
          value={newPassword}
          onChange={onChange}
          required
        />

        <label>Confirm New Password</label>
        <input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={onChange}
          required
        />
        <label>BO Username</label>
        <input
          type="text"
          name="BOUsername"
          value={BOUsername}
          onChange={onChange}
          required
        />
        <label>BO Password</label>
        <input
          type="password"
          name="BOPassword"
          value={BOPassword}
          onChange={onChange}
          required
        />
        

        <button type="submit">Change Password</button>
      </form>
    </div>
  );
};

export default ChangePassword;
