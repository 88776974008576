import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);  
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  useEffect(() => {
    const token = localStorage.getItem('token');
    const validateToken = async (token) => {
      try {
        const response = await axios.post(`${axiosDomain}/api/auth/validate-login`,{}, {
          headers: { 'Authorization': `Bearer ${token}` }
        });        
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    }
    if (token) {      
      validateToken(token);
    } else {
      console.log('No token found');
      setIsAuthenticated(false);
    }
  }, []);

  const login = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);    
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);    
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
