import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Grid, Box, Typography, Alert, MenuItem, InputLabel, FormControl } from '@mui/material';
import './FormStyles.css';
import axios from 'axios';

//import Select from 'react-select';

const SARForm = () => {
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const [boLender, setBOLender] = useState('');
  const [boAccNumber, setBOAccNumber] = useState('');
  const [boFirstName, setBOFirstName] = useState('');
  const [boLastName, setBOLastName] = useState('');
  const [validated, setValidated] = useState(false);
  const [additionalValidated, setAdditionalValidated] = useState(false);
  const [creditDateInValid, setCreditDateInValid] = useState(false);  
  const invalidDate = new Date('2021-01-28');

  const [accountMismatch, setAccountMismatch] = useState(false); 
  const [formData, setFormData] = useState({
    caReference: '',
    creditAgreementProvided: 'Yes',
    creditAgreementStartDate: '',
    creditAgreementEndDate: '',
    commissionType: 'Not Specified / Not Found',
    termOfAgreement: '',
    agreementCancelled: 'Not Specified',
    sarResponseType: 'Not Specified',
    sarRejectionReason: 'Not Specified',
    sarResponseReceivedDate: '',
    sarHoldingResponseExpiryDate: '',
    sarNoMeritDate: '',
    startDateNotSpecified: false,
    endDateNotSpecified: false,
    termDateNotSpecified: false,
    holdingDateNotSpecified: false,
    meritDateNotSpecified: false,
    accountNumber: '',
    totalCostofCredit: '',
    deposit: '',
    brokerDetails: '',
    brokerFee: '',
    APR: '',
    lender: '',
    otherRejectionReason: '',
  });

  const [additionalCases, setAdditionalCases] = useState([]);
  const [currentAdditionalCase, setCurrentAdditionalCase] = useState({    
    make: '',
    model: '',
    registrationNumber: '',
    bodyDescription: '',
    colour: '',
    fuelType: '',
    firstRegistrationDate: '',
    cc: '',
    engineSize: '',
    torque: '',
    bhp: '',
    euroStatus: '',
    manufacturedYear: '',
    additionalAccountNumber: '',    
    additionalCreditAgreementStartDate: '',
    additionalCreditAgreementEndDate: '',
    additionalCreditAgreementProvided: 'No',
    additionalCommissionType: 'Not Specified / Not Found',
    additionalTermofAgreement: '',
    additionalTermNotSpecified: '',
    additionalCancelled: 'Not Specified',
    additionalTotalCostofCredit: '',
    additionalDeposit: '',
    additionalBrokerDetails: '',
    additionalBrokerFee: '',
    startDateNotSpecified: false,
    endDateNotSpecified: false,
    additionalAPR: ''
  });
  const [editIndex, setEditIndex] = useState(null);
  const [editAdditionalIndex, setEditAdditionalIndex] = useState(null);

  const [errors, setErrors] = useState({});
  const [queue, setQueue] = useState([]);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [showAdditionalCases, setShowAdditionalCases] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [additionalFormVisible, setAdditionalFormVisible] = useState(true);

  const onChange = e => {
    setMessage({ type: '', text: '' });
    const { name, value, type, checked } = e.target;
    if (name === 'caReference') {
      setBOAccNumber('');
      setBOFirstName('');
      setBOLastName('');
      setBOLender('');
    }
    
    if (name === 'sarResponseType' && value === 'Rejection') {
      setFormData(prevState => ({
        ...prevState,
        creditAgreementProvided: 'Yes',
        creditAgreementStartDate: '',
        creditAgreementEndDate: '',
        commissionType: '',
        termOfAgreement: '',
        agreementCancelled: '',                        
        sarHoldingResponseExpiryDate: '',
        sarNoMeritDate: '',
        startDateNotSpecified: false,
        endDateNotSpecified: false,
        termDateNotSpecified: false,
        holdingDateNotSpecified: false,
        meritDateNotSpecified: false,
        accountNumber: '',
        totalCostofCredit: '',
        deposit: '',
        brokerDetails: '',
        brokerFee: '',
        APR: '',
        lender: '',        
      }));
    }

    if ((name === 'sarRejectionReason' && value !== 'Other') || (name ==='sarResponseType' && value !== 'Rejection')) {
      setFormData(prevState => ({
        ...prevState,
        otherRejectionReason: ''
      }));
    }    
    
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
    
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }

    if (name === 'startDateNotSpecified' ) {      
      if (formData.creditAgreementStartDate) {
        const startDate = new Date(formData.creditAgreementStartDate);
        if (startDate >= invalidDate) {
          setCreditDateInValid(true);          
        } else {
          setCreditDateInValid(false);
        }
      } else {
        setCreditDateInValid(false);
      }
      
    }
    
    if (name === 'creditAgreementStartDate') {
      const selectedDate = new Date(value);
      if (selectedDate >= invalidDate) {
        setCreditDateInValid(true);
        setFormData(prevState => ({
          ...prevState,
          sarResponseType: 'Rejection',
          sarRejectionReason: 'Out of Scope'          
        }));
      }
       else {
        setCreditDateInValid(false);
      }
    }
    
    if (name === 'creditAgreementProvided' && value === 'No') {
      formData.startDateNotSpecified = true;
      formData.endDateNotSpecified = true;
    } else {
      formData.startDateNotSpecified = false;
      formData.endDateNotSpecified = false;
    }     
    
  };

  const handleAdditionalCaseChange = e => {
    setMessage({ type: '', text: '' });
    const { name, value, type, checked } = e.target;
    if (name === 'additionalAccountNumber') {
      setAccountMismatch(false); 
     }
     
    if (name === 'additionalCreditAgreementStartDate') {
      const selectedDate = new Date(value);
      if (selectedDate >= invalidDate) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [name]: 'The agreement start date should be after 28/01/2021'
        }))
        //setAdditionalValidated(false);
      }
    }

    setCurrentAdditionalCase(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));

    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ['caReference', 'creditAgreementProvided', 'commissionType',
                            'agreementCancelled', 'sarResponseType', 'sarRejectionReason',
                            'sarResponseReceivedDate'
                          ];
    if (!formData.sarResponseType === 'Rejection') {                  
      requiredFields.forEach(field => {
        if (!formData[field]) {
          newErrors[field] = 'This field is required';
        }
      });
    }

    if (formData.creditAgreementProvided === 'Yes') {
      if (!formData.creditAgreementStartDate && !formData.startDateNotSpecified) {
        newErrors.creditAgreementStartDate = 'This field is required';
      }
      

      if (!formData.sarResponseType === 'Rejection') {
        if (!formData.creditAgreementEndDate && !formData.endDateNotSpecified) {
          newErrors.creditAgreementEndDate = 'This field is required';
        }
      
        if (!formData.termOfAgreement && !formData.termDateNotSpecified) {
          newErrors.termOfAgreement = 'This field is required';
        }
      }
    }
    
    if (!creditDateInValid && !formData.sarResponseType === 'Rejection') {
      if (!formData.sarHoldingResponseExpiryDate && !formData.holdingDateNotSpecified) {
        newErrors.sarHoldingResponseExpiryDate = 'This field is required';
      }
      if (!formData.sarNoMeritDate && !formData.meritDateNotSpecified) {
        newErrors.sarNoMeritDate = 'This field is required';
      }
    }
    if (!formData.sarResponseReceivedDate) {
      newErrors.sarResponseReceivedDate = 'This field is required';
    }

    console.log('Errors:', newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateAdditionalCase = async () => {        
    const newErrors = {};            
    if ((!currentAdditionalCase.additionalCreditAgreementStartDate || currentAdditionalCase.additionalCreditAgreementStartDate === '') && (!currentAdditionalCase.startDateNotSpecified || currentAdditionalCase.startDateNotSpecified === false)) {
      newErrors.additionalCreditAgreementStartDate = 'Start Date is required';
      setAdditionalValidated(false);
    }
    if ((!currentAdditionalCase.additionalCreditAgreementEndDate || currentAdditionalCase.additionalCreditAgreementEndDate === '') && (!currentAdditionalCase.endDateNotSpecified || currentAdditionalCase.endDateNotSpecified === false)) {
      newErrors.additionalCreditAgreementEndDate = 'End Date is required';
      setAdditionalValidated(false);
    }
    
    if (currentAdditionalCase.additionalAccountNumber !== '') {
      const accountExists = await checkAccountExists(currentAdditionalCase.additionalAccountNumber);    
      if (!accountExists) {
        newErrors.additionalAccountNumber = 'Account Number already exists';
        setAdditionalValidated(false);
      }
    }
    
    const agreementDate = new Date(currentAdditionalCase.additionalCreditAgreementStartDate);
    if (agreementDate >= invalidDate) {
      newErrors.additionalCreditAgreementStartDate = 'The agreement start date should be after 28/01/2021';
      setAdditionalValidated(false);
    }
    
    setErrors(newErrors);
    console.log('New errors:', newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const checkCAReferenceExists = async (caRef) => {
    try {
      const response = await axios.post(`${axiosDomain}/api/retrieve-info`, { caReference: caRef }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.errormsg) {
        setErrors(prevErrors => ({
          ...prevErrors,
          caReference: response.data.errormsg
        }));
        setValidated(false);
        return false;
      }
      setBOAccNumber(response.data.accountNumber);
      setBOFirstName(response.data.firstName);
      setBOLastName(response.data.lastName);
      setBOLender(response.data.lender);      
      setFormData(prevState => ({
        ...prevState,
        lender: response.data.lender,
        accountNumber: response.data.accountNumber
      }));
      setValidated(true);
      setAccountMismatch(false);
    } catch (error) {
      setErrors(prevErrors => ({
        ...prevErrors,
        caReference: 'Could not fetch data from the database'
      }));
      setValidated(false);
    }
  };

  const checkAccountExists = async (anum) => {    
    try {
      const response = await axios.post(`${axiosDomain}/api/retrieve-account`, { anum: anum }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });            
      if (response.data.errormsg) {
        setErrors(prevErrors => ({
          ...prevErrors,
          additionalAccountNumber: response.data.errormsg
        }));
        setAccountMismatch(true);
        return false;
      }           
      return true;                   
    } catch (error) {
      setErrors(prevErrors => ({
        ...prevErrors,
        additionalAccountNumber: 'Could not fetch check the account nuber'
      }));      
      setAccountMismatch(true);
      return false;
    }
  };

  const addToQueue = async () => {
    if (!validated) {
      setMessage({ type: 'error', text: 'Please check the CA Reference. Could not find it in the data' });
      return;
    }
    
    // const accExists = await checkAccountExists(formData.accountNumber);    
    // if (accountMismatch || !accExists) {
    //   setMessage({ type: 'error', text: 'Account Number already exists' });
    //   return;
    // }

    if (!validateForm()) {
      console.log('form not validated');
      return;
    }      
    
    if (editIndex === null) {
      if (queue.some(item => item.caReference === formData.caReference)) {
        setMessage({ type: 'error', text: 'CA Reference already exists in queue. Please edit or delete it before adding.' });
        return;
      }
    }

    const formDataWithAdditionalCases = {
      ...formData,
      additionalCases
    };

    if (editIndex !== null) {
      const updatedQueue = queue.map((item, index) =>
        index === editIndex ? formDataWithAdditionalCases : item
      );
      setQueue(updatedQueue);
      setEditIndex(null);
      setMessage({ type: 'success', text: 'SAR Response updated successfully.' });
    } else {
      setQueue([...queue, formDataWithAdditionalCases]);
      setMessage({ type: 'success', text: 'SAR Response added to queue successfully.' });
    }

    setFormData({
      caReference: '',
      creditAgreementProvided: 'Yes',
      creditAgreementStartDate: '',
      creditAgreementEndDate: '',
      commissionType: 'Not Specified / Not Found',
      termOfAgreement: '',
      agreementCancelled: 'Not Specified',
      sarResponseType: 'Not Specified',
      sarRejectionReason: 'Not Specified',
      sarResponseReceivedDate: '',
      sarHoldingResponseExpiryDate: '',
      sarNoMeritDate: '',
      startDateNotSpecified: false,
      endDateNotSpecified: false,
      termDateNotSpecified: false,
      holdingDateNotSpecified: false,
      meritDateNotSpecified: false,
      accountNumber: '',
      totalCostofCredit: '',
      deposit: '',
      brokerDetails: '',
      brokerFee: '',      
      APR: '',
      lender: '',
      otherRejectionReason: '',
    });
    setCreditDateInValid(false);
    setAdditionalCases([]);
  };

  const addToAdditionalQueue = async () => {    
    const validation = await validateAdditionalCase();    
    console.log('validation:', validation);
    if (!validation) {
      const errorLog = Object.keys(errors).map(key => `${key}: ${errors[key]}`).join(', ');
      console.log(errorLog);
      setMessage({ type: 'error', text: `The additional case could not be validated or added to the queue. Please ensure you have filled the required fields and check if you have added a primary case to the queue.` });
      return;
    }
        
    //if (!additionalValidated) {
      //setMessage({ type: 'error', text: 'The additional case could not be validated or added to the queue. Please ensure you have filled the required fields and check if you have added a primary case to the queue' });
      //return;
    //}
    // if (!validateAdditionalCase()) {      
    //     setMessage({ type: 'error', text: 'The additional case could not be validated or added to the queue. Please ensure you have filled all the required fields and check if you have added a primary case to the queue' });
    //     return;            
    // }    
    
    if (editAdditionalIndex !== null) {
      const updatedAdditionalCases = additionalCases.map((item, index) =>
        index === editAdditionalIndex ? currentAdditionalCase : item
      );
      setAdditionalCases(updatedAdditionalCases);
      setEditAdditionalIndex(null);
    } else {
      setAdditionalCases([...additionalCases, currentAdditionalCase]);
    }

    setCurrentAdditionalCase({      
      make: '',
      model: '',
      registrationNumber: '',
      bodyDescription: '',
      colour: '',
      fuelType: '',
      firstRegistrationDate: '',
      cc: '',
      engineSize: '',
      torque: '',
      bhp: '',
      euroStatus: '',
      manufacturedYear: '',
      additionalAccountNumber: '',
      additionalCreditAgreementStartDate: '',
      additionalCreditAgreementEndDate: '',
      additionalCreditAgreementProvided: 'No',
      additionalCommissionType: 'Not Specified / Not Found',
      additionalTermofAgreement: '',
      additionalTermNotSpecified: '',
      additionalCancelled: 'Not Specified',
      additionalTotalCostofCredit: '',
      additionalDeposit: '',
      additionalBrokerDetails: '',
      additionalBrokerFee: '',
      startDateNotSpecified: false,
      endDateNotSpecified: false,
      additionalAPR: ''
    });
    setMessage({ type: 'success', text: 'Additional case added to queue successfully.' });
  };

  const onEdit = index => {
    setFormData(queue[index]);
    setEditIndex(index);
    setAdditionalCases(queue[index].additionalCases || []);
  };

  const onEditAdditional = index => {
    setCurrentAdditionalCase(additionalCases[index]);
    setEditAdditionalIndex(index);
  };

  const onDelete = index => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      const updatedQueue = queue.filter((_, i) => i !== index);
      setQueue(updatedQueue);
      if (index === editIndex) {
        setEditIndex(null);
      }
    }
  };

  const onDeleteAdditional = index => {
    if (window.confirm('Are you sure you want to delete this additional case?')) {
      const updatedAdditionalCases = additionalCases.filter((_, i) => i !== index);
      setAdditionalCases(updatedAdditionalCases);
      if (index === editAdditionalIndex) {
        setEditAdditionalIndex(null);
        setCurrentAdditionalCase({});
      }
    }
  };

  const onSubmit = async () => {
    const formattedQueue = queue.map(item => {
      const formattedItem = { ...item };
      // Format primary case dates
      const dateFields = [
        'creditAgreementStartDate',
        'creditAgreementEndDate',
        'sarResponseReceivedDate',
        'sarHoldingResponseExpiryDate',
        'sarNoMeritDate'
      ];
      dateFields.forEach(field => {
        formattedItem[field] = formatDateToDDMMYYYY(formattedItem[field]);
      });

      // Format additional cases dates
      if (formattedItem.additionalCases && formattedItem.additionalCases.length > 0) {
        formattedItem.additionalCases = formattedItem.additionalCases.map(addCase => {
          const formattedAddCase = { ...addCase };
          const additionalDateFields = [
            'additionalCreditAgreementStartDate',
            'additionalCreditAgreementEndDate',
            'firstRegistrationDate'
          ];
          additionalDateFields.forEach(field => {
            formattedAddCase[field] = formatDateToDDMMYYYY(formattedAddCase[field]);
          });
          return formattedAddCase;
        });
      }
      return formattedItem;
    });

    try {
      await axios.post(`${axiosDomain}/api/sar-submit`, { SARData: formattedQueue }, {
      //await axios.post(`${axiosDomain}/api/sar-submit`, { SARData: queue }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setMessage({ type: 'success', text: 'SAR Responses submitted successfully.' });
      setQueue([]);
      setAdditionalCases([]);
      setBOLender('');
      setBOAccNumber('');
      setBOFirstName('');
      setBOLastName('');
      setFormData({
        caReference: '',
        creditAgreementProvided: 'Yes',
        creditAgreementStartDate: '',
        creditAgreementEndDate: '',
        commissionType: 'Not Specified / Not Found',
        termOfAgreement: '',
        agreementCancelled: 'Not Specified',
        sarResponseType: 'Not Specified',
        sarRejectionReason: 'Not Specified',
        sarResponseReceivedDate: '',
        sarHoldingResponseExpiryDate: '',
        sarNoMeritDate: '',
        startDateNotSpecified: false,
        endDateNotSpecified: false,
        termDateNotSpecified: false,
        holdingDateNotSpecified: false,
        meritDateNotSpecified: false,
        accountNumber: '',
        totalCostofCredit: '',
        deposit: '',
        brokerDetails: '',
        brokerFee: '',
        APR: '',
        lender: '',
        otherRejectionReason: '',
      });
      setCreditDateInValid(false);
      setCurrentAdditionalCase({        
        make: '',
        model: '',
        registrationNumber: '',
        bodyDescription: '',
        colour: '',
        fuelType: '',
        firstRegistrationDate: '',
        cc: '',
        engineSize: '',
        torque: '',
        bhp: '',
        euroStatus: '',
        manufacturedYear: '',
        additionalAccountNumber: '',
        additionalCreditAgreementStartDate: '',
        additionalCreditAgreementEndDate: '',
        additionalCreditAgreementProvided: 'No',
        additionalCommissionType: 'Not Specified / Not Found',
        additionalTermofAgreement: '',
        additionalTermNotSpecified: '',
        additionalCancelled: 'Not Specified',
        additionalTotalCostofCredit: '',
        additionalDeposit: '',
        additionalBrokerDetails: '',
        additionalBrokerFee: '',
        startDateNotSpecified: false,
        endDateNotSpecified: false,
        additionalAPR: '',
      });

      
    } catch (error) {
      console.error('Failed to submit SAR responses:', error);
      setMessage({ type: 'error', text: 'Failed to submit SAR responses.' });
    }
  };

  function formatDateToDDMMYYYY(dateString) {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  }

  return (
    <Box className="sar-form-container" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', maxWidth: 1280, margin: '20px auto' }}>
      <Box className="form-container" sx={{ backgroundColor: '#f7f7f7', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '70%', marginRight: '20px' }}>
        {message.text && (
          <Alert severity={message.type} sx={{ marginBottom: '20px' }}>
            {message.text}
          </Alert>
        )}
        {boLender && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <strong>First Name:</strong> {boFirstName}
              </Grid>
              <Grid item xs={6}>
                <strong>Last Name:</strong> {boLastName}
              </Grid>
              <Grid item xs={6}>
                <strong>Lender:</strong> {boLender}
              </Grid>
            </Grid>
            <br /><br />
          </>
        )}
        <Button onClick={() => setFormVisible(!formVisible)}>
          {formVisible ? 'Hide Primary Form' : 'Show Primary Form'}
        </Button>
        {formVisible && (
          <form onSubmit={e => e.preventDefault()}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="CA Reference"
                  name="caReference"
                  value={formData.caReference}
                  onChange={onChange}
                  fullWidth
                  error={!!errors.caReference}
                  helperText={errors.caReference}
                  onBlur={(e) => checkCAReferenceExists(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                  <TextField
                    label="SAR Response Received Date"
                    type="date"
                    name="sarResponseReceivedDate"
                    value={formData.sarResponseReceivedDate}
                    onChange={onChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.sarResponseReceivedDate}
                    helperText={errors.sarResponseReceivedDate}
                  />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="SAR Response Type"
                  name="sarResponseType"
                  value={formData.sarResponseType}
                  onChange={onChange}
                  select
                  SelectProps={{ native: true }}
                  fullWidth
                  disabled={creditDateInValid}
                >
                  <option value="Holding">Holding</option>
                  <option value="Acceptance">Acceptance</option>
                  <option value="Rejection">Rejection</option>
                  <option value="Not Specified">Not Specified</option>
                  <option value="Full Response">Full Response</option>                  
                </TextField>
              </Grid>
              {(formData.sarResponseType === 'Rejection' || creditDateInValid) && (
                <Grid item xs={6}>
                  <TextField
                    label="SAR Rejection Reason"
                    name="sarRejectionReason"
                    value={formData.sarRejectionReason}
                    onChange={onChange}
                    select
                    SelectProps={{ native: true }}
                    fullWidth
                    disabled={creditDateInValid}                  
                  >
                    <option value="ID Requested">ID Requested</option>
                    <option value="Invalid LOA">Invalid LOA</option>
                    <option value="UTL Client">UTL Client</option>
                    <option value="UTL Agreement">UTL Agreement</option>
                    <option value="Not Specified">Not Specified</option>
                    <option value="No DCA">No DCA</option>
                    <option value="Out of Scope">Out of Scope</option>
                    <option value="IVA Bankruptcy">IVA Bankruptcy</option>
                    <option value="Other">Other</option>
                    {/* <option value="Other">Other</option>  open a text box - connect with a BO field */}                  
                  </TextField>
                  { formData.sarRejectionReason === 'Other' && (
                    <>
                    <br/><br/>
                    <Grid item xs={12}>
                      <TextField
                        label="Other Rejection Reason"
                        name="otherRejectionReason"
                        value={formData.otherRejectionReason}
                        onChange={onChange}
                        fullWidth
                        error={!!errors.otherRejectionReason}
                        helperText={errors.otherRejectionReason}
                        InputLabelProps={{ shrink: true }}
                        disabled={formData.sarRejectionReason !== 'Other'}                                        
                      />
                    </Grid>
                    </>
                  )}                
                </Grid>
              )}

              <Grid item xs={6}>
                <TextField
                  label="Credit Agreement Provided"
                  name="creditAgreementProvided"
                  value={formData.creditAgreementProvided}
                  onChange={onChange}
                  select
                  SelectProps={{ native: true }}
                  fullWidth
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Credit Agreement Start Date"
                  type="date"
                  name="creditAgreementStartDate"
                  value={formData.creditAgreementStartDate}
                  onChange={onChange}
                  fullWidth
                  error={!!errors.creditAgreementStartDate}
                  helperText={errors.creditAgreementStartDate}
                  InputLabelProps={{ shrink: true }}
                  disabled={formData.startDateNotSpecified}
                />
                <Grid container justifyContent="flex-start">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="startDateNotSpecified"
                        checked={formData.startDateNotSpecified}
                        onChange={onChange}
                      />
                    }
                    label="Not Specified"
                  />
                </Grid>
              </Grid>

                                          
              {(formData.sarResponseType !== 'Rejection') && (
                <>                              
                <Grid item xs={6}>
                  <TextField
                    label="Credit Agreement End Date"
                    type="date"
                    name="creditAgreementEndDate"
                    value={formData.creditAgreementEndDate}
                    onChange={onChange}
                    fullWidth
                    error={!!errors.creditAgreementEndDate}
                    helperText={errors.creditAgreementEndDate}
                    InputLabelProps={{ shrink: true }}
                    disabled={formData.endDateNotSpecified}
                  />
                  <Grid container justifyContent="flex-start">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="endDateNotSpecified"
                          checked={formData.endDateNotSpecified}
                          onChange={onChange}
                        />
                      }
                      label="Not Specified"
                    />
                  </Grid>
                </Grid>
                </>
              )}
              {(formData.sarResponseType !== 'Rejection' && !creditDateInValid) && (
                <>
                <Grid item xs={6}>
                  <TextField
                    label="Commission Type"
                    name="commissionType"
                    value={formData.commissionType}
                    onChange={onChange}
                    select
                    SelectProps={{ native: true }}
                    fullWidth
                  >
                    <option value="DCA">DCA</option>
                    <option value="Non DCA">Non DCA</option>
                    <option value="Not Specified / Not Found">Not Specified / Not Found</option>
                    <option value="No Commission">No Commission</option>
                    <option value="Fixed Fee">Fixed Fee</option>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                      label="Term of Agreement"
                      type="number"
                      name="termOfAgreement"
                      value={formData.termOfAgreement}
                      onChange={onChange}                    
                      fullWidth
                      error={!!errors.termOfAgreement}
                      helperText={errors.termOfAgreement}
                      InputLabelProps={{ shrink: true }}
                      disabled={formData.termDateNotSpecified}
                    />                
                  <Grid container justifyContent="flex-start">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="termDateNotSpecified"
                          checked={formData.termDateNotSpecified}
                          onChange={onChange}
                        />
                      }
                      label="Not Specified"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Agreement Cancelled in 30 Days"
                    name="agreementCancelled"
                    value={formData.agreementCancelled}
                    onChange={onChange}
                    select
                    SelectProps={{ native: true }}
                    fullWidth
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Specified">Not Specified</option>
                  </TextField>
                </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="SAR Holding Response Expiry Date"
                      type="date"
                      name="sarHoldingResponseExpiryDate"
                      value={formData.sarHoldingResponseExpiryDate}
                      onChange={onChange}
                      fullWidth
                      error={!!errors.sarHoldingResponseExpiryDate}
                      helperText={errors.sarHoldingResponseExpiryDate}
                      InputLabelProps={{ shrink: true }}
                      disabled={formData.holdingDateNotSpecified}                  
                    />
                    <Grid container justifyContent="flex-start">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="holdingDateNotSpecified"
                            checked={formData.holdingDateNotSpecified}
                            onChange={onChange}
                          />
                        }
                        label="Not Specified"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="SAR No Merit Date"
                      type="date"
                      name="sarNoMeritDate"
                      value={formData.sarNoMeritDate}
                      onChange={onChange}
                      fullWidth
                      error={!!errors.sarNoMeritDate}
                      helperText={errors.sarNoMeritDate}
                      InputLabelProps={{ shrink: true }}
                      disabled={formData.meritDateNotSpecified}
                    />
                    <Grid container justifyContent="flex-start">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="meritDateNotSpecified"
                            checked={formData.meritDateNotSpecified}
                            onChange={onChange}
                          />
                        }
                        label="Not Specified"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Account Number"
                      name="accountNumber"
                      value={formData.accountNumber}
                      onChange={onChange}
                      onBlur={(e) => checkAccountExists(e.target.value)}
                      fullWidth
                      error={!!errors.accountNumber}
                      helperText={errors.accountNumber}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      type="number"
                      label="Total Cost of Credit"
                      name="totalCostofCredit"
                      value={formData.totalCostofCredit}
                      onChange={onChange}                  
                      fullWidth
                      error={!!errors.totalCostofCredit}
                      helperText={errors.totalCostofCredit}
                      InputLabelProps={{ shrink: true }}                  
                    />                
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      type="number"
                      label="What was the deposit (advanced payment)?"
                      name="deposit"
                      value={formData.deposit}
                      onChange={onChange}                  
                      fullWidth
                      error={!!errors.deposit}
                      helperText={errors.deposit}
                      InputLabelProps={{ shrink: true }}                  
                    />                
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Broker Details"
                      name="brokerDetails"
                      value={formData.brokerDetails}
                      onChange={onChange}                  
                      fullWidth
                      error={!!errors.brokerDetails}
                      helperText={errors.brokerDetails}
                      InputLabelProps={{ shrink: true }}                  
                    />                
                  </Grid>
                  
                  <Grid item xs={6}>
                    <TextField
                      type="number"
                      label="Broker Fee"
                      name="brokerFee"
                      value={formData.brokerFee}
                      onChange={onChange}                  
                      fullWidth
                      error={!!errors.brokerFee}
                      helperText={errors.brokerFee}
                      InputLabelProps={{ shrink: true }}                  
                    />                
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      type="number"
                      label="APR"
                      name="APR"
                      value={formData.APR}
                      onChange={onChange}                  
                      fullWidth
                      error={!!errors.APR}
                      helperText={errors.APR}
                      InputLabelProps={{ shrink: true }}                  
                    />                
                  </Grid>
                  </>
                )}
                {/* Add Make and Model and Registration plate - Don't make the change yet*/}                
              </Grid>                   
          </form>
        )}
        <br />
        

        <div className="additional-container">
          <Checkbox
            checked={showAdditionalCases}
            onChange={() => setShowAdditionalCases(!showAdditionalCases)}
            inputProps={{ 'aria-label': 'controlled' }}
            
          />
          
          <Typography variant="body1" sx={{ display: 'inline-block', marginLeft: '10px' }}>
            Additional Cases
          </Typography>        
        </div>        
        {showAdditionalCases && (
          <Box>            
            {additionalFormVisible && (
              <form onSubmit={e => e.preventDefault()}>
                <Grid container spacing={3}>
                  
                  <Grid item xs={6}>
                    <TextField
                      label="Make"
                      name="make"
                      value={currentAdditionalCase.make}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      error={!!errors.make}
                      helperText={errors.make}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Model"
                      name="model"
                      value={currentAdditionalCase.model}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      error={!!errors.model}
                      helperText={errors.model}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Registration Number"
                      name="registrationNumber"
                      value={currentAdditionalCase.registrationNumber}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      error={!!errors.registrationNumber}
                      helperText={errors.registrationNumber}
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <TextField
                      label="Body Description"
                      name="bodyDescription"
                      value={currentAdditionalCase.bodyDescription}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Colour"
                      name="colour"
                      value={currentAdditionalCase.colour}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Fuel Type"
                      name="fuelType"
                      value={currentAdditionalCase.fuelType}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="First Registration Date"
                      type="date"
                      name="firstRegistrationDate"
                      value={currentAdditionalCase.firstRegistrationDate}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="CC"
                      name="cc"
                      value={currentAdditionalCase.cc}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Engine Size"
                      name="engineSize"
                      value={currentAdditionalCase.engineSize}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Torque"
                      name="torque"
                      value={currentAdditionalCase.torque}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Bhp"
                      name="bhp"
                      value={currentAdditionalCase.bhp}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Euro Status"
                      name="euroStatus"
                      value={currentAdditionalCase.euroStatus}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Manufactured Year"
                      name="manufacturedYear"
                      value={currentAdditionalCase.manufacturedYear}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                    />
                  </Grid> */}
                  <Grid item xs={6}>
                    <TextField
                      label="Account Number"
                      name="additionalAccountNumber"
                      value={currentAdditionalCase.additionalAccountNumber}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      error={!!errors.additionalAccountNumber}
                      helperText={errors.additionalAccountNumber}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Credit Agreement Provided"
                      name="additionalCreditAgreementProvided"
                      value={currentAdditionalCase.additionalCreditAgreementProvided}
                      onChange={handleAdditionalCaseChange}
                      select
                      SelectProps={{ native: true }}
                      fullWidth
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Credit Agreement Start Date"
                      type="date"
                      name="additionalCreditAgreementStartDate"
                      value={currentAdditionalCase.additionalCreditAgreementStartDate}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.additionalCreditAgreementStartDate}
                      helperText={errors.additionalCreditAgreementStartDate}
                      disabled={currentAdditionalCase.startDateNotSpecified}
                    />
                    <Grid container justifyContent="flex-start">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="startDateNotSpecified"
                            checked={currentAdditionalCase.startDateNotSpecified}
                            onChange={handleAdditionalCaseChange}
                          />
                        }
                        label="Not Specified"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Credit Agreement End Date"
                      type="date"
                      name="additionalCreditAgreementEndDate"
                      value={currentAdditionalCase.additionalCreditAgreementEndDate}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.additionalCreditAgreementEndDate}
                      helperText={errors.additionalCreditAgreementEndDate}
                      disabled={currentAdditionalCase.endDateNotSpecified}
                    />
                    <Grid container justifyContent="flex-start">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="endDateNotSpecified"
                            checked={currentAdditionalCase.endDateNotSpecified}
                            onChange={handleAdditionalCaseChange}
                          />
                        }
                        label="Not Specified"
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Commission Type"
                      name="additionalCommissionType"
                      value={currentAdditionalCase.additionalCommissionType}
                      onChange={handleAdditionalCaseChange}
                      select
                      SelectProps={{ native: true }}
                      fullWidth                      
                    >
                      <option value="DCA">DCA</option>
                      <option value="Non DCA">Non DCA</option>
                      <option value="Not Specified / Not Found">Not Specified / Not Found</option>
                      <option value="No Commission">No Commission</option>
                      <option value="Fixed Fee">Fixed Fee</option>
                    </TextField>                  
                  </Grid>     
                  <Grid item xs={6}>
                    <TextField
                      label="Term of Agreement"
                      name="additionalTermofAgreement"
                      value={currentAdditionalCase.additionalTermofAgreement}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      error={!!errors.additionalTermofAgreement}
                      helperText={errors.additionalTermofAgreement}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  
                  <Grid item xs={6}>
                    <TextField
                      label="Agreement Cancelled in 30 Days"
                      name="additionalCancelled"
                      value={currentAdditionalCase.additionalCancelled}
                      onChange={handleAdditionalCaseChange}
                      select
                      SelectProps={{ native: true }}
                      fullWidth
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Not Specified">Not Specified</option>
                    </TextField>
                  </Grid>

                   <Grid item xs={6}>
                    <TextField
                      label="Total Cost of Credit"
                      name="additionalTotalCostofCredit"
                      value={currentAdditionalCase.additionalTotalCostofCredit}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      error={!!errors.additionalTotalCostofCredit}
                      helperText={errors.additionalTotalCostofCredit}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>   
                  <Grid item xs={6}>
                    <TextField
                      label="What was the deposit (advance payment)?"
                      name="additionalDeposit"
                      value={currentAdditionalCase.additionalDeposit}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      error={!!errors.additionalDeposit}
                      helperText={errors.additionalDeposit}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>     
                  <Grid item xs={6}>
                    <TextField
                      label="Broker Details"
                      name="additionalBrokerDetails"
                      value={currentAdditionalCase.additionalBrokerDetails}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      error={!!errors.additionalBrokerDetails}
                      helperText={errors.additionalBrokerDetails}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>   
                  <Grid item xs={6}>
                    <TextField
                      label="Broker Fee"
                      name="additionalBrokerFee"
                      value={currentAdditionalCase.additionalBrokerFee}
                      onChange={handleAdditionalCaseChange}
                      fullWidth
                      error={!!errors.additionalBrokerFee}
                      helperText={errors.additionalBrokerFee}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      type="number"
                      label="APR"
                      name="additionalAPR"
                      value={currentAdditionalCase.additionalAPR}
                      onChange={handleAdditionalCaseChange}                  
                      fullWidth
                      error={!!errors.additionalAPR}
                      helperText={errors.additionalAPR}
                      InputLabelProps={{ shrink: true }}                  
                    />                
                  </Grid>   
                                    
                </Grid>
                <Button
                  type="button"
                  onClick={addToAdditionalQueue}
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: '20px', width: '100%', backgroundColor: '#a2a2a2 !important' }}
                >
                  {editAdditionalIndex !== null ? 'Update Additional Case' : 'Add Additional Case'}
                </Button>
              </form>
            )}

            {/* <Box className="additional-cases-queue" sx={{ backgroundColor: '#f7f7f7', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '30%', maxHeight: '80vh', overflowY: 'auto', marginTop: '20px' }}>
              <Typography variant="h5" sx={{ marginBottom: '20px' }}>Additional Cases Queue</Typography>
              {additionalCases.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {additionalCases.map((item, index) => (
                      <tr key={index}>
                        <td>{item.make}</td>
                        <td>{item.model}</td>
                        <td>
                          <Button onClick={() => onEditAdditional(index)}>Edit</Button>
                          <Button onClick={() => onDeleteAdditional(index)}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Typography variant="body1">No additional cases in queue</Typography>
              )}
            </Box> */}
          </Box>
        )}

        <Button
          type="button"
          onClick={addToQueue}
          variant="contained"
          color="primary"
          sx={{ marginTop: '20px', width: '100%' }}
        >
          {editIndex !== null ? 'Update' : 'Add To Queue'}
        </Button>
      </Box>

      <Box className="queue-container" sx={{ backgroundColor: '#f7f7f7', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '30%', maxHeight: '80vh', overflowY: 'auto' }}>
        <Typography variant="h5" sx={{ marginBottom: '20px' }}>Queue</Typography>
        {queue.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>CA Reference</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {queue.map((item, index) => (
                <tr key={index}>
                  <td>{item.caReference}</td>
                  <td>
                    <Button onClick={() => onEdit(index)}>Edit</Button>
                    <Button onClick={() => onDelete(index)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Typography variant="body1">No items in queue</Typography>
        )}
        {queue.length > 0 && (
          <Button onClick={onSubmit} variant="contained" color="secondary" sx={{ marginTop: '20px', width: '100%' }}>
            Submit
          </Button>
        )}


            <Box className="additional-cases-queue" sx={{ backgroundColor: '#f7f7f7', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '88%', maxHeight: '80vh', overflowY: 'auto', marginTop: '20px' }}>
              <Typography variant="h5" sx={{ marginBottom: '20px' }}>Additional Cases Queue</Typography>
              {additionalCases.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {additionalCases.map((item, index) => (
                      <tr key={index}>
                        <td>{item.make}</td>
                        <td>{item.model}</td>
                        <td>
                          <Button onClick={() => onEditAdditional(index)}>Edit</Button>
                          <Button onClick={() => onDeleteAdditional(index)}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Typography variant="body1">No additional cases in queue</Typography>
              )}
            </Box>

        
      </Box>


      
    </Box>
  );
};

export default SARForm;
