import React from 'react';
import './Dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <h1>Welcome to Your Dashboard</h1>
      <p>Select an option from the navigation bar to get started.</p>
    </div>
  );
};

export default Dashboard;