import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Tooltip, CircularProgress, Container } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';

const PreviousJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${axiosDomain}/api/auth/previous-jobs`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setJobs(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching previous jobs:', error);
        setLoading(false);
      }
    };
    fetchJobs();
  }, []);

  const handleDownload = async (jobID) => {
    try {
      const response = await axios.get(`${axiosDomain}/api/auth/download-report`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params: { id: jobID },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container sx={{ marginTop: '40px', maxWidth: 'lg' }}>
      <TableContainer component={Paper} sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#1976d2' }}>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Job ID</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>CA References</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Additional Cases</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => (
              <TableRow key={job.jobId}>
                <TableCell>{job.jobId}</TableCell>
                <TableCell>
                  <Tooltip title={`
                    Commission Type: ${job.commissionType || 'N/A'}
                    SAR Response Type: ${job.sarResponseType || 'N/A'}
                    SAR No Merit Date: ${job.sarNoMeritDate || 'N/A'}
                  `}>
                    <span>{job.caReferences}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={`Additional Cases Count: ${job.additionalCasesCount || '0'}`}>
                    <span>{job.additionalCasesCount || 0}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>{job.status}</TableCell>
                {job.status === 'completed' &&
                  <TableCell>
                    <Button
                      variant="contained"
                      startIcon={<DownloadIcon />}
                      onClick={() => handleDownload(job.jobId)}
                      sx={{ backgroundColor: '#1976d2', color: 'white', '&:hover': { backgroundColor: '#1565c0' } }}
                    >
                      Download
                    </Button>                
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default PreviousJobs;
