import React, { useState } from 'react';
import axios from 'axios';
import './AuthForm.css'; // Import your CSS for styles
import { useAuth } from '../authContext'; // Adjust path as necessary

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${axiosDomain}/api/auth/login`, { username, password });
      login(data.token);            
    } catch (error) {
      alert('Failed to login');
    }
  };

  return (
    <div className="auth-form">
      <form onSubmit={handleLogin}>
        <label>Username</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
