import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login'; // Adjust path as necessary
import Register from './components/Register'; // Adjust path as necessary
import SARForm from './components/ResponseForm'; // Adjust path as necessary
import ChangePassword from './components/ChangePassword';
import PreviousJobs from './components/PreviousJobs';
import { AuthProvider, useAuth } from './authContext'; // Adjust path as necessary
import './App.css';
import NavBar from './components/NavBar';
import Dashboard from './components/Dashboard';

const ApplicationRoutes = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {        
    return (
      <>
      <NavBar />
      <Routes>
        {/*<Route path="/" element={<SARForm />} />*/}
        <Route path="/" element={<Dashboard />} />
        <Route path="/response-form" element={<SARForm />} />      
        <Route path="/change-password" element={<ChangePassword />} />    
        <Route path="/previous-jobs" element={<PreviousJobs />} />
      </Routes>
      </>
    )
  } else {
    return (
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<Login />} />

      </Routes>
    )
  }  
}


function App() {
  return (    
    <>
      <header className="form-header">
          <img src="/clllogo.png" alt="Logo" className="logo" /> 
      </header>

      <div className="App">
        <AuthProvider>
          <Router>
            <ApplicationRoutes />
          </Router>
        </AuthProvider>        
      </div>    
    </>
  );
}

export default App;