import React, { useState } from 'react';
import axios from 'axios';
import './AuthForm.css'; // Ensure you're using the same styling as for the login form
import {useNavigate} from 'react-router-dom';

const Register = () => {
  const axiosDomain = process.env.REACT_APP_AXIOS_DOMAIN;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    BO_user_name: '',
    BO_password: ''
  });

  const { username, password, confirmPassword, firstName, lastName, BO_user_name, BO_password } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const newUser = {
        username,
        password,
        confirmPassword,
        firstName,
        lastName,
        BO_user_name,
        BO_password
      };
      if (!username || !password || !confirmPassword || !firstName || !lastName || !BO_user_name || !BO_password) {
        alert('Please fill in all fields');
        return;
      }
      if (password.length < 6) {
        alert('Password must be at least 6 characters');
        return;
      }
      if (password !== confirmPassword) {
        alert('Passwords do not match');
        return;
      }

      const result = await axios.post(`${axiosDomain}/api/auth/register`, newUser);
      alert('Registration successful');
      setFormData({
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        BO_user_name: '',
        BO_password: ''
      })
      navigate('/');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {        
        alert(error.response.data.message);
      } else {        
        alert('An unexpected error occurred');
        console.error(error);
      }
    }
  };

  return (
    <div className="auth-form">
      <form onSubmit={onSubmit}>
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          name="firstName"
          value={firstName}
          onChange={onChange}
          required
        />

        <label htmlFor="lastName">Last Name</label>
        <input
          type="text"
          name="lastName"
          value={lastName}
          onChange={onChange}
          required
        />

        <label htmlFor="username">Username</label>
        <input
          type="text"
          name="username"
          value={username}
          onChange={onChange}
          required
        />

        <label htmlFor="password">Password</label>
        <input
          type="password"
          name="password"
          value={password}
          onChange={onChange}
          required
        />
        <label htmlFor="confirmPassword">Confirm Password</label>
        <input
          type="password"
          name="confirmPassword"
          required
          value={confirmPassword}
          onChange={onChange}
        />

        <label htmlFor="BO_user_name">BO Username</label>
        <input
          type="text"
          name="BO_user_name"
          value={BO_user_name}
          onChange={onChange}
          required
        />

        <label htmlFor="BO_password">BO Password</label>
        <input
          type="password"
          name="BO_password"
          value={BO_password}
          onChange={onChange}
          required
        />

        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;
