import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import { AuthProvider, useAuth } from '../authContext';

const NavBar = () => {
  const { logout } = useAuth();
  return (
    <nav className="navbar">
      <div className="navbar-brand">CLL SAR Response Update Tool</div>
      <ul className="navbar-links">
        <li><Link to="/">Dashboard</Link></li>
        <li><Link to="/response-form">SAR Form</Link></li>
        <li><Link to="/previous-jobs">Previous Jobs</Link></li>
        <li><Link to="/change-password">Change Password</Link></li>
        <li><Link to="/" onClick={logout}>Logout</Link></li>
      </ul>
    </nav>
  );
};

export default NavBar;